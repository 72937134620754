<template>
<!-- 热点左侧页面 -->
  <div>
    <van-list v-model="loading" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished" finished-text="没有更多了" @load="scroll">
    <div class="Hot" v-for="(item, index) in List" :key="index" >
      <a :href="item.url" class="Hot_a" target="_blank">
        <div class="Hot_left">
        <img :src="item.img" v-if="item.img != ''" alt="" />
        <img
          src="https://images.qudonghao.cn//uploads/20210425/b16d16ccf672077c53ef60445a04c4ec.jpg"
          v-if="item.img == ''"
          alt=""
        />
      </div>
      <div class="Hot_right">
        <div class="Hot_right_top">
          {{ item.title }}
        </div>
        <div class="Hot_right_bottom">
          <div class="Hot_right_bottom_left">
            <img
              v-if="item.user_headimgurl != ''"
              :src="item.user_headimgurl"
              alt=""
            />
            <img
              v-if="item.user_headimgurl == ''"
              src="http://www.qudonghao.cn/static/headerimg.png"
              alt=""
            />

            <div class="name">{{ item.user_nickname }}</div>
            <div class="data">{{ item.published }}</div>
          </div>
          <div class="Hot_right_bottom_right">
            <div class="Hot_right_bottom_right_num">
              <img src="../assets/img/dianzan_icon.png" alt="" />
              <span>{{ item.b }}</span>
            </div>
            <div class="Hot_right_bottom_right_num">
              <img src="../assets/img/pinglun_icon.png" alt="" />
              <span>{{ item.comments }}</span>
            </div>
          </div>
        </div>
      </div>
      </a>
    </div>
   </van-list>
  </div>
</template>

<script>
import Axios from "axios";
export default {
   metaInfo(){
     return{
        title: '热点',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      List: [],
      load: true,
      page: 2,
      loading: false,
      finished: false,
    };
  },
  methods: {
    request() {
      let that = this;
       // 开始加载
      let loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: "拼命加载中，请稍候...",//显示在加载图标下方的加载文案
        background: "rgba(0,0,0,0.8)",//遮罩层颜色
        spinner: "el-icon-loading",//自定义加载图标类名
      });
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/index/index",
       params: {
          // category: 823,
        },
      }).then((res) => {
        //console.log(res);
        loading.close();
        that.List = res.data.data;
      });
    },
    //分页
    scroll() {
      var that = this;
    Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/index/index",
        params: {
          page: this.page,
        },
      }).then((res) => {
        setTimeout(() => {
          this.page = this.page + 1;
          if (res.data.data.length > 0) {
            this.List.push(...res.data.data);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }, 500);
      });
    },
  },
  created: function () {
    var that = this;
    that.request();
  },
};
</script>

<style scoped>
.Hot {
  width: 860px;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  cursor: pointer;
}
.Hot_a{
  display:flex;
}
.Hot_left img {
  width: 215px;
  height: 145px;
  border-radius: 8px;
  object-fit: cover;
}
.Hot_right {
  width: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Hot_right_top {
  margin-top: 10px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_right_bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width:600px;
}
.Hot_right_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_right_bottom_left img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.Hot_right_bottom_left .name {
  font-size: 14px;
  margin-right: 25px;
}
.Hot_right_bottom_left .data {
  font-size: 13px;
  color: #999999;
}
.Hot_right_bottom_right {
  display: flex;
}
.Hot_right_bottom_right_num {
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.Hot_right_bottom_right_num img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.Hot_right_bottom_right_num span {
  color: #999999;
}
>>> .van-list__finished-text {
  width: 900px;
}
>>> .van-list__loading {
  width: 900px;
}
</style>